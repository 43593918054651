import { IRoute } from 'types/index'

const routes: IRoute[] = [
  {
    name: 'login',
    path: '/',
    component: `Login${process.env.REACT_APP_COUNTRY}`,
    secure: false
  },
  {
    name: 'home',
    path: '/home',
    component: 'Home',
    secure: false,
  },
  {
    name: 'file',
    path: '/file',
    component: `File`,
    secure: false,
  },
  {
    name: 'permissions',
    path: '/permissions',
    component: 'Permissions',
    secure: false
  },
  {
    name: 'filters',
    path: '/filters',
    component: 'Filters',
    secure: false
  },
  {
    name: 'analysis',
    path: '/analysis',
    component: 'Analysis',
    secure: false
  },
  {
    name: 'innovation',
    path: '/innovation',
    component: 'Innovation',
    secure: false
  }
];

export default routes;
